<template>
  <div class="view-wrapper profile" v-if="user">
    <ProfileHeader :user="user" @handleProfileModal="handleUploadModal"></ProfileHeader>
    <nav-bar @changeTab="handleChanegTab"></nav-bar>
    <div class="outfits-rating">
      <div class="container">
        <template v-if="currentTab == 1">
          <div class="outfit-rate" v-if="waiting.length > 0">
            <div class="title-outfit">
              <h4 class="h4-style" v-text="title1"></h4>
            </div>
            <div>
              <template v-for="(item, i) in waiting">
                <outfit-image
                  v-if="item.outfit_items.length > 0"
                  :key="'key-rate-' + i"
                  :image_path="item.outfit_items[0].image_path"
                  :item="item.outfit_items[0]"
                  :outfit="item"
                  :index="i"
                  :deadline="item.deadline"
                  :hasGallery="true"
                  @handleProfileReload="getRatedOutfits"
                  @handleGallery="handlePendingGal">
                </outfit-image>
              </template>
            </div>
          </div>
          <div class="outfits" v-if="rated.length > 0">
            <div class="title-outfit">
              <h4 class="h4-style" v-text="title2"></h4>
              <div class="sorting-outfits">
                <div>
                  <multiselect
                    @input="handleSort"
                    :options="base.outfitSortings"
                    track-by="value"
                    label="title"
                    :searchable="false"
                    :show-labels="false"
                    :placeholder="__t('profile.sorting')">
                  </multiselect>
                </div>
              </div>
            </div>
            <div>
              <template v-for="(item, i) in rated">
                <outfit-image
                  v-if="item.outfit_items.length > 0"
                  :key="'key-rate-' + i"
                  :index="i"
                  :item="item.outfit_items[0]"
                  :outfit="item"
                  :hasGallery="true"
                  @handleGallery="handleCustomGallery">
                </outfit-image>
              </template>
            </div>
          </div>
        </template>
        <template v-else>
          <!--- Favourities -->
          <div class="outfits" v-if="favourites.length > 0">
            <div class="title-outfit">
              <h4 class="h4-style">Favourities</h4>
              <!-- To Be immplemented --->
              <!-- <div class="sorting-outfits">
                <div>
                  <multiselect
                    @input="handleSort"
                    :options="base.outfitSortings"
                    track-by="value"
                    label="title"
                    :searchable="false"
                    :show-labels="false"
                    :placeholder="__t('profile.sorting')">
                  </multiselect>
                </div>
              </div> -->
            </div>
            <div>
              <template v-for="(item, i) in favourites">
                <outfit-image
                  v-if="item.outfit.outfit_items.length > 0"
                  :key="'key-rate-' + i"
                  :index="i"
                  :item="item.outfit.outfit_items[0]"
                  :outfit="item.outfit"
                  :hasGallery="true"
                  :isFavouriteOutfit="true"
                  :favouriteOutfit="item"
                  @handleGallery="handleFavouriteGallery">
                </outfit-image>
              </template>
            </div>
          </div>
        </template>

      </div>
    </div>
    <OutfitModal
      v-if="galleryModal"
      :outfit="currentOutfit"
      :favouriteOutfit="isFavouriteOutfit ? favouriteOutfit : {}"
      :isFavouriteOutfit="isFavouriteOutfit"
      @handleDelete="handleItemDelete"
      @handleGallery="handleCustomGallery"
      @togglePinOutfit="handleTogglePinOutfit"
      @unSavedOutfit="handleUnsavedOutfit">
    </OutfitModal>
    <stars-popup
      v-if="!user.has_seen_stars_badge && user.stars > 0"
      :stars="user.stars"
      @handleStars="handleStars"></stars-popup>
    <div class="popup-profile-image-upload" v-if="profileModal" @click.self="handleUploadModal(false)">
      <profile-upload :isProfile="true" @handleProfileModal="handleUploadModal"></profile-upload>
    </div>
    <feedback @handleFeedback="handleFeedbackModal" v-if="feedbackModal"></feedback>
  </div>
</template>

<script>
import Multiselect from 'vue-multiselect'
import { mapActions, mapState } from 'vuex'
import StarsPopup from '@/components/stars-popup/Star'
import OutfitImage from '@/components/outfit/OutfitImage'
import OutfitModal from '@/components/outfit/OutfitModal'
import ProfileHeader from '@/components/profile/ProfileHeader'
import ProfileUpload from '@/components/profile/ProfileUpload'
import Feedback from '@/components/modals/Feedback'
import NavBar from '@/components/profile/NavBar'
export default {
  components: {
    ProfileHeader,
    OutfitImage,
    OutfitModal,
    Multiselect,
    StarsPopup,
    ProfileUpload,
    Feedback,
    NavBar
  },
  data () {
    return {
      title1: this.__t('profile.pending-list-title'),
      title2: this.__t('profile.rated-list-title'),
      sorting: '',
      options: [
        {
          id: 0,
          title: this.__t('profile.sorting-recent')
        },
        {
          id: 1,
          title: this.__t('profile.sorting-oldest')

        },
        {
          id: 2,
          title: this.__t('profile.sorting-best-rated')

        }
      ],
      galleryModal: false,
      profileModal: false,
      feedbackModal: false,
      currentTab: 1,
      isFavouriteOutfit: false,
      favouriteOutfit: {}
    }
  },
  computed: {
    ...mapState('auth', ['base', 'user']),
    ...mapState('outfit', ['waiting', 'rated', 'favourites'])
  },
  methods: {
    ...mapActions('user', ['getSettings', 'getPage']),
    ...mapActions('auth', ['searchUser', 'markSeenBadge']),
    ...mapActions('outfit', ['deleteOutfitItem', 'getRatedOutfits', 'togglePin', 'unSavedOutfit']),
    handleCustomGallery (val, i) {
      this.isFavouriteOutfit = false
      this.galleryModal = val
      this.currentOutfit = this.rated[i]
    },
    handlePendingGal (val, i) {
      this.galleryModal = val
      this.currentOutfit = this.waiting[i]
    },
    handleFavouriteGallery (val, i) {
      this.isFavouriteOutfit = true
      this.galleryModal = val
      this.currentOutfit = this.favourites[i].outfit
      this.favouriteOutfit = this.favourites[i]
    },
    handleItemDelete (id) {
      this.handleCustomGallery(false, null)
      this.deleteOutfitItem(id).then(resp => {
        resp && this.getRatedOutfits()
      })
    },
    handleTogglePinOutfit (favouriteOutfit, pin) {
      this.handleCustomGallery(false, null)
      let data = {
        id: favouriteOutfit.id,
        pin: pin
      }
      this.togglePin(data)
    },
    handleSort (val) {
      this.getRatedOutfits(val.value)
    },
    handleStars () {
      this.markSeenBadge()
    },
    getBestRate (item) {
      let iRate = 0
      let el = null
      item.outfit_items.forEach(element => {
        if (element.rating > iRate) {
          iRate = element.rating
          el = element
        }
      })
      return el
    },
    handleUploadModal (val) {
      this.profileModal = val
    },
    registerEventsForPendingOutfits () {
      if (this.waiting.length > 0) {
        let pusher = new window.Pusher(process.env.VUE_APP_PUSHER_KEY, {
          cluster: 'eu',
          encrypted: false
        })
        let self = this
        this.waiting.forEach(function (item) {
          var channel = pusher.subscribe('outfit-' + item.id)
          channel.bind('outfit.rated', function () {
            self.getRatedOutfits()
          })
        })
      }
    },
    handleFeedbackModal (val) {
      this.feedbackModal = val
    },
    handleChanegTab (tab) {
      this.currentTab = tab
    },
    handleUnsavedOutfit (favouriteOutfit) {
      this.unSavedOutfit(favouriteOutfit.id)
      this.handleCustomGallery(false, null)
    }
  },
  created () {
    this.searchUser().then(resp => {
      resp && this.getRatedOutfits().then(() => {
        this.registerEventsForPendingOutfits()
      })
    })
  },
  watch: {
    waiting: function () {
      this.registerEventsForPendingOutfits()
      if (this.$route.query.o !== undefined && this.rated.length > 0) {
        let self = this
        let activeOutfit = this.rated.filter(obj => {
          return obj.id === parseInt(self.$route.query.o)
        })

        if (activeOutfit.length === 1) {
          this.currentOutfit = activeOutfit[0]
          this.galleryModal = true
        }
      }
    },
    rated: function (nv, ov) {
      let totalPostiveRating = 0
      if (nv.length === 2 && !this.user.is_feedback_given) {
        nv.forEach((item) => {
          if (item.status === 1) {
            totalPostiveRating += 1
          }
        })
        if (totalPostiveRating) {
          setTimeout(() => {
            this.feedbackModal = true
          }, 10000)
        }
      }
    }
  },
  mounted () {
  }
}
</script>
