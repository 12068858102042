<template>
    <div class="modal-wrapper feedback-modal" @click.self="handleFeedback()">
        <div class="modal-wrapper-body">
            <div class="modal-wrapper-body-in">
                <img src="@/assets/images/general/wear-it-logo.svg"/>
                    <h2 class="title mt-3 mb-3">would u use WearitOut again?</h2>
                <div class="row mt-2">
                    <div class="col-10 offset-1 col-md-8 offset-md-2">
                        <button type="button" :class="[choice === 1 ? 'active' : '','btn-default','yes']" @click="toggleChoice(1)">100%</button>
                        <button type="button" :class="[choice === 0 ? 'active' : '','btn-default', 'no']" @click="toggleChoice(0)">no way</button>
                    </div>
                </div>
                <div class="row">
                    <div class="col-10 offset-1">
                        <h2>tell us why, bestie</h2>
                    </div>
                    <div class="col-10 offset-1 col-md-8 offset-md-2">
                        <textarea class="form-control feedback-text" v-model="feedback"></textarea>
                    </div>
                    <div class="col-12 mr-5 mt-3 col-md-7">
                        <button type="button" @click.prevent="submit()" :disabled="!isSubmitDisable" class="btn-default btn-submit">SUBMIT</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapActions } from 'vuex'
export default {
  data () {
    return {
      feedback: '',
      choice: null,
      isSubmitDisable: false
    }
  },
  methods: {
    ...mapActions('feedback', ['createFeedback']),
    toggleChoice (val) {
      this.choice = val
      this.validateForm()
    },
    validateForm () {
      if (this.choice == null) {
        this.isSubmitDisable = false
      } else {
        this.isSubmitDisable = true
      }
    },
    submit () {
      let data = {
        choice: this.choice,
        feedback: this.feedback
      }
      this.createFeedback(data)
        .then(response => {
          this.resetFields()
          this.$emit('handleFeedback', false)
        }).catch(error => {
          console.log('err', error)
        })
    },
    resetFields () {
      this.choice = null
      this.feedback = ''
    },
    handleFeedback () {
      this.$emit('handleFeedback', false)
    }
  }
}
</script>
