<template>
    <div class="profile-navbar">
        <div class="container profile-navbar-wrapper">
          <div class="nav-item" @click.prevent="changeTab(1)" :class="selectedTab === 1 ? 'active' : ''">
            <a href="" class="nav-item-link">
              <span class="nav-item-link-title mr-2">HISTORY</span>
              <template v-if="selectedTab === 1">
                <svg width="30" height="30" viewBox="0 0 23 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M10.0001 2H20.0001V2C21.1047 2 22.0001 2.89543 22.0001 4V4V20.5V20.5C22.0001 21.3284 21.3285 22 20.5001 22H20.0001H4.00012V22C2.89555 22 2.00012 21.1046 2.00012 20V20V8.5" stroke="#333333" stroke-linecap="round"/>
                  <path d="M1.32566 3.82322L2.14809 5.04565L3.37017 4.22345M2.30746 4.93842C2.19815 3.76302 2.71752 2.5659 3.76571 1.8607C5.29304 0.833128 7.3645 1.23833 8.39207 2.76567C9.41964 4.29301 9.01444 6.36446 7.4871 7.39203C5.95976 8.4196 3.88831 8.0144 2.86074 6.48706" stroke="#333333" stroke-linecap="round" stroke-linejoin="round"/>
                  <path d="M5.00012 3V4.93792L6.52346 5.86667" stroke="#333333" stroke-linecap="round" stroke-linejoin="round"/>
                  <path d="M8.74381 8.76272C8.74381 8.76272 8.5158 8.37437 5.0385 11.9805C5.0385 11.9805 4.24043 12.2025 4.01241 12.8682C3.78439 13.534 6.80565 15.3648 6.80565 15.3648C6.80565 15.3648 7.71773 14.6275 7.77473 14.3578C7.83174 14.0882 8.74381 20.9993 8.74381 20.9993H11.8398C11.8398 20.9993 12.8048 18.0827 13.3618 16.4461C13.4792 16.1004 13.6223 15.7642 13.7933 15.4408C14.1787 14.7123 14.9129 12.8732 14.2163 10.3711C14.2163 10.3711 13.9472 9.06231 14.1644 8.57243C14.2026 8.48699 14.1775 8.38657 14.1028 8.32943C14.0151 8.2623 13.9056 8.15467 13.8036 7.98767C13.7466 7.89447 13.6103 7.88282 13.5431 7.96936C13.2649 8.32887 12.2736 8.80655 8.80082 8.31889L8.74381 8.76272Z" fill="#FFFD0B"/>
                  <path d="M16.2115 8.23959C16.2115 8.23959 17.0928 8.13251 17.8264 9.7098C17.8418 9.74253 17.8886 9.74419 17.9057 9.71257C18.0123 9.51617 18.3269 9.01353 18.8554 8.78662C19.0258 8.71338 19.2156 8.69397 19.3998 8.72393C19.6232 8.75999 19.9795 8.74501 19.8723 8.24625C19.8712 8.24125 19.8712 8.23626 19.8723 8.23127C19.8911 8.1192 20.1112 6.75107 19.8427 6.49309C19.8233 6.47423 19.8284 6.44261 19.8507 6.42763C20.0485 6.29337 20.8021 5.99544 20.9999 9.07123C20.9999 9.07234 20.9999 9.07289 20.9999 9.074C20.9999 9.12171 20.9834 10.7051 19.9168 12.2352C19.9168 12.2352 19.0617 13.5667 19.4038 14.8982C19.4038 14.8982 20.6949 19.5962 20.325 20.9694C20.3198 20.9882 20.3021 21.001 20.2822 21.001H12.4093C12.3768 21.001 12.3608 20.9705 12.3693 20.94C13.0335 18.6076 13.7033 16.5626 13.8743 16.2852C15.8124 11.6249 14.4004 11.8563 14.7864 9.18385C15.3119 5.54495 16.2765 6.02041 16.3825 6.02041C16.5535 6.02041 16.6755 6.17409 16.6105 6.35329C16.3876 6.96911 15.9481 8.31282 16.2115 8.23959Z" fill="#FFFD0B"/>
                </svg>

              </template>
              <template v-else>
                <svg width="30" height="30" viewBox="0 0 23 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M10.0001 2H20.0001V2C21.1047 2 22.0001 2.89543 22.0001 4V4V20.5V20.5C22.0001 21.3284 21.3285 22 20.5001 22H20.0001H4.00012V22C2.89555 22 2.00012 21.1046 2.00012 20V20V8.5" stroke="#333333" stroke-linecap="round"/>
                  <path d="M1.32566 3.82322L2.14809 5.04565L3.37017 4.22345M2.30746 4.93842C2.19815 3.76302 2.71752 2.5659 3.76571 1.8607C5.29304 0.833128 7.3645 1.23833 8.39207 2.76567C9.41964 4.29301 9.01444 6.36446 7.4871 7.39203C5.95976 8.4196 3.88831 8.0144 2.86074 6.48706" stroke="#333333" stroke-linecap="round" stroke-linejoin="round"/>
                  <path d="M5.00012 3V4.93792L6.52346 5.86667" stroke="#333333" stroke-linecap="round" stroke-linejoin="round"/>
                  <path d="M8.74381 8.76272C8.74381 8.76272 8.5158 8.37437 5.0385 11.9805C5.0385 11.9805 4.24043 12.2025 4.01241 12.8682C3.78439 13.534 6.80565 15.3648 6.80565 15.3648C6.80565 15.3648 7.71773 14.6275 7.77473 14.3578C7.83174 14.0882 8.74381 20.9993 8.74381 20.9993H11.8398C11.8398 20.9993 12.8048 18.0827 13.3618 16.4461C13.4792 16.1004 13.6223 15.7642 13.7933 15.4408C14.1787 14.7123 14.9129 12.8732 14.2163 10.3711C14.2163 10.3711 13.9472 9.06231 14.1644 8.57243C14.2026 8.48699 14.1775 8.38657 14.1028 8.32943C14.0151 8.2623 13.9056 8.15467 13.8036 7.98767C13.7466 7.89447 13.6103 7.88282 13.5431 7.96936C13.2649 8.32887 12.2736 8.80655 8.80082 8.31889L8.74381 8.76272Z" fill="#333333"/>
                  <path d="M16.2115 8.23959C16.2115 8.23959 17.0928 8.13251 17.8264 9.7098C17.8418 9.74253 17.8886 9.74419 17.9057 9.71257C18.0123 9.51617 18.3269 9.01353 18.8554 8.78662C19.0258 8.71338 19.2156 8.69397 19.3998 8.72393C19.6232 8.75999 19.9795 8.74501 19.8723 8.24625C19.8712 8.24125 19.8712 8.23626 19.8723 8.23127C19.8911 8.1192 20.1112 6.75107 19.8427 6.49309C19.8233 6.47423 19.8284 6.44261 19.8507 6.42763C20.0485 6.29337 20.8021 5.99544 20.9999 9.07123C20.9999 9.07234 20.9999 9.07289 20.9999 9.074C20.9999 9.12171 20.9834 10.7051 19.9168 12.2352C19.9168 12.2352 19.0617 13.5667 19.4038 14.8982C19.4038 14.8982 20.6949 19.5962 20.325 20.9694C20.3198 20.9882 20.3021 21.001 20.2822 21.001H12.4093C12.3768 21.001 12.3608 20.9705 12.3693 20.94C13.0335 18.6076 13.7033 16.5626 13.8743 16.2852C15.8124 11.6249 14.4004 11.8563 14.7864 9.18385C15.3119 5.54495 16.2765 6.02041 16.3825 6.02041C16.5535 6.02041 16.6755 6.17409 16.6105 6.35329C16.3876 6.96911 15.9481 8.31282 16.2115 8.23959Z" fill="#333333"/>
                </svg>

              </template>
            </a>
          </div>
          <div class="nav-item" @click.prevent="changeTab(2)" :class="selectedTab === 2 ? 'active' : ''">
            <a href="" class="nav-item-link">
              <span class="nav-item-link-title mr-2">SAVED</span>
              <template v-if="selectedTab === 2">
                <svg width="30" height="30" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M5.5 6.5C5.5 4.84315 6.84315 3.5 8.5 3.5H15.5C17.1569 3.5 18.5 4.84315 18.5 6.5V18.216C18.5 19.0878 17.4623 19.5421 16.8217 18.9508L12.6783 15.1261C12.2952 14.7725 11.7048 14.7725 11.3217 15.1261L7.17828 18.9508C6.53771 19.5421 5.5 19.0878 5.5 18.216V6.5Z" fill="#FFFD0B" stroke="#333333" stroke-width="1.5"/>
                </svg>

              </template>
              <template v-else>
                <svg width="30" height="30" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <rect width="24" height="24" fill="white"/>
                  <path d="M5.5 6.5C5.5 4.84315 6.84315 3.5 8.5 3.5H15.5C17.1569 3.5 18.5 4.84315 18.5 6.5V18.216C18.5 19.0878 17.4623 19.5421 16.8217 18.9508L12.6783 15.1261C12.2952 14.7725 11.7048 14.7725 11.3217 15.1261L7.17828 18.9508C6.53771 19.5421 5.5 19.0878 5.5 18.216V6.5Z" stroke="#333333" stroke-width="1.5"/>
                </svg>
              </template>
            </a>
          </div>
        </div>
        <!-- <div class="navbar-divider"></div> -->
    </div>
</template>

<script>
export default {
  data () {
    return {
      selectedTab: 1
    }
  },
  methods: {
    changeTab (tab) {
      this.selectedTab = tab
      this.$emit('changeTab', tab)
    }
  }
}
</script>
