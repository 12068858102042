<template>
  <div class="profile-header" v-if="user">
    <div class="container">
      <div class="profile-header-left">
        <div class="profile-header-left-avatar" :class="{ 'no-border': user.profile_image.includes('avatar')}"
             @click.stop="$emit('handleProfileModal', true)">
          <img :src="user.profile_image" width="100%" alt=""/>

          <img v-if="user.stars === 3" class="verified" :src="require('@/assets/images/icons/verified.svg')"/>
        </div>
        <div class="profile-header-left-content">
          <h4 class="h4-style" v-text="user.username"></h4>
          <h5 class="h5-style-lg" v-text="user.subline"></h5>
        </div>
      </div>
      <div class="profile-header-right">
        <div class="profile-header-right-sticker" v-if="user.stars && user.stars > 2">
          <div class="profile-header-right-sticker-in">
            <icon-profile-coin :color="'FFFD0B'"></icon-profile-coin>
          </div>
          <div class="profile-header-right-sticker-text">
            <br/>
          </div>
        </div>
        <div class="profile-header-right-sticker" v-if="user.stars && user.stars > 1">
          <div class="profile-header-right-sticker-in">
            <icon-profile-coin :color="'F4F4F4'"></icon-profile-coin>
          </div>
          <div class="profile-header-right-sticker-text">
            <br/>
          </div>
        </div>
        <div class="profile-header-right-sticker">
          <div class="profile-header-right-sticker-in">
            <icon-profile-coin v-if="user.stars && user.stars > 0"></icon-profile-coin>
          </div>
          <div class="profile-header-right-sticker-text" v-if="user.stars && user.stars > 0">
            <br/>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import iconProfileCoin from '@/components/icons/icon-profile-coin'

export default {
  components: {
    iconProfileCoin
  },
  props: {
    user: {
      type: Object,
      default: null,
      required: true
    }
  }
}
</script>
